
import { defineComponent, ref } from "vue";
import axios from "axios";
import { Modal } from "bootstrap";
import Template516 from "@/views/templates/Template516.vue";
import CollectionCategory from "@/components/ThemeLesson/CollectionCategory.vue";
import CollectionCategoryEmpty from "@/components/MyFavoritePost/CollectionCategoryEmpty.vue";
import AddToFavoriteClassificationModal from "@/components/ThemeLesson/AddToFavoriteClassificationModal.vue";
import NewClassificationModal from "@/components/ThemeLesson/NewClassificationModal.vue";
import DeleteClassificationModal from "@/components/ThemeLesson/DeleteClassificationModal.vue";
import FavoritePostCard from "@/components/MyFavoritePost/FavoritePostCard.vue";
import Toast from "@/components/Toast/Toast.vue";
import mixins from "@/mixins/index";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    Template516,
    CollectionCategory,
    CollectionCategoryEmpty,
    AddToFavoriteClassificationModal,
    NewClassificationModal,
    FavoritePostCard,
    DeleteClassificationModal,
    Toast,
  },
  mixins: [mixins],
  data() {
    return {
      spinnerStatus: true,
      classificationSpinnerStatus: true,
      showChange: "all-favorite-post", // all-favorite-post , specify-category-post
      renameController: false,
      renameBuffer: "",
      // Modal
      addToFavoriteClassification: null as any,
      newClassification: null as any,
      mobileFavoriteRecommendedShow: "favorite",
      showMoreOptions: false,
      favoritePostList: [] as any,
      // myCollectionCategory: [
      //   {
      //     id: "1",
      //     title:
      //       "1基礎英文課:被動式 Be V +基礎英文課:被動式 Be V + 1基礎英文課:被動式 Be V +基礎英文課:被動式 Be V + 1基礎英文課:被動式 Be V +基礎英文課:被動式 Be V + 1基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "Ryan",
      //     picture:
      //       "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIBqhV_AWIsKy5ILAmIryH3CGtp1hZ4ePcJOWcub7R&s",
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "2基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "aaa",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "3基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "bbb",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "4基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "ccc",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "5基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "Ryan",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "5基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "Ryan",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "5基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "Ryan",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      //   {
      //     id: "1",
      //     title: "5基礎英文課:被動式 Be V +基礎英文課:被動式 Be V +",
      //     name: "Ryan",
      //     picture: null,
      //     type: 2,
      //     favoriteStatus: true,
      //   },
      // ],
      // 我的收藏分類
      myCollectionCategory: [] as any,
      myCollectionCategoryId: 0,
      specifyCategoryPostTitle: "指定分類",
      specifyCategoryPostList: [] as any,
      deleteClassification: null as any,
      followPostInfo: {
        postId: 0,
        postIndex: 0,
        status: false,
        from: "my-favorite-post",
      },
      toastText: "",
      nameLimit: false,
      modalId: "MyFavoritePost",
    };
  },
  methods: {
    // obj.info就是要顯示的文字內容
    showToast(obj: { info: string }) {
      this.toastText = obj.info;
      (this as any).$refs.Toast.showToast();
    },
    openModalNewClassification() {
      this.newClassification.show();
    },
    closeModalNewClassification() {
      this.newClassification.hide();
    },
    openModalDeleteClassification() {
      this.deleteClassification.show();
    },
    closeModalDeleteClassification() {
      this.deleteClassification.hide();
    },
    closeAddToFavoriteClassificationModal() {
      this.addToFavoriteClassification.hide();
    },
    moveToCollect(obj: { id: number }) {
      // console.log(obj.id);
      this.followPostInfo.postId = obj.id;
      (this as any).$refs.AddToFavoriteClassificationModal.favoriteListGet();
      this.addToFavoriteClassification.show();
    },
    removeFavoritePost(obj: { id: number }) {
      this.showToast({ info: "已取消收藏" });
      if (this.favoritePostList.length > 0) {
        for (let i = 0; i < this.favoritePostList.length; i++) {
          if (this.favoritePostList[i].id === obj.id) {
            this.favoritePostList.splice(i, 1);
          }
        }
      }
      if (this.specifyCategoryPostList.length > 0) {
        for (let i = 0; i < this.specifyCategoryPostList.length; i++) {
          if (this.specifyCategoryPostList[i].id === obj.id) {
            this.specifyCategoryPostList.splice(i, 1);
          }
        }
      }
    },
    pageChange(page: string) {
      this.showChange = page;
    },
    collectionCategoryGet() {
      this.classificationSpinnerStatus = true;
      axios
        .get(`${serverUrl}thematic_learning/collect`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          this.myCollectionCategory = [];
          this.myCollectionCategory = res.data.data;
          this.classificationSpinnerStatus = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    favoritePostGet(obj: {
      page: number;
      displayedNum: number;
      collect?: number;
    }) {
      let url = "";
      if (obj.collect === undefined) {
        url = `${serverUrl}thematic_learning/post/collect?page=${obj.page}&displayedNum=${obj.displayedNum}`;
      } else {
        url = `${serverUrl}thematic_learning/post/collect?page=1&displayedNum=999999&collect=${obj.collect}`;
      }
      this.spinnerStatus = true;
      axios
        .get(url, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // this.favoritePostList = res.data.data.data;
          this.favoritePostList = [];
          this.specifyCategoryPostList = [];
          for (let i = 0; i < res.data.data.data.length; i++) {
            let buffer = {
              content: "",
              description1: "Ryan / 洪振瑋",
              description2: "生活英文",
              id: 0,
              user_id: 0,
              image: null,
              collect_id: 0,
              type: res.data.data.data[i].type,
            };
            // buffer.content = res.data.data.data[i].content;
            if (
              res.data.data.data[i].title !== null &&
              res.data.data.data[i].title !== undefined
            ) {
              buffer.content = `${res.data.data.data[i].title}${res.data.data.data[i].content}`;
            } else {
              buffer.content = res.data.data.data[i].content;
            }
            if (
              res.data.data.data[i].foreignName !== null &&
              !res.data.data.data[i].official
            ) {
              buffer.description1 = `${res.data.data.data[i].foreignName}`;
            } else {
              buffer.description1 = `${res.data.data.data[i].cnName}`;
            }
            buffer.description2 = res.data.data.data[i].collect;
            buffer.id = res.data.data.data[i].id;
            buffer.user_id = res.data.data.data[i].user_id;
            buffer.image = res.data.data.data[i].imageUrl;
            if (buffer.image) {
              buffer.image = res.data.data.data[i].imageUrl.replace(
                /amp;/g,
                "&"
              );
            }
            buffer.collect_id = res.data.data.data[i].collect_id;

            if (obj.collect === undefined) {
              this.favoritePostList.push(buffer);
            } else {
              this.specifyCategoryPostList.push(buffer);
            }
          }
          this.spinnerStatus = false;
          // console.log(this.specifyCategoryPostList);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showSpecifyCategoryPost(obj: { collect: number }) {
      // console.log(obj);
      this.favoritePostGet({
        page: 1,
        displayedNum: 9999999,
        collect: obj.collect,
      });
    },
    changeCollectionCategory(obj: { title: string; id: number }) {
      this.renameController = false;
      this.renameBuffer = "";
      this.pageChange("specify-category-post");
      this.showSpecifyCategoryPost({ collect: obj.id });
      this.specifyCategoryPostTitle = obj.title;
      this.myCollectionCategoryId = obj.id;
    },
    deleteCollectionCategory(obj: { id: number }) {
      // 畫面上先刪除該分類
      for (let i = 0; i < this.myCollectionCategory.length; i++) {
        if (this.myCollectionCategory[i].id === obj.id) {
          this.myCollectionCategory.splice(i, 1);
        }
      }
      axios
        .delete(`${serverUrl}thematic_learning/collect/${obj.id}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
      this.closeModalDeleteClassification();
      this.pageChange("all-favorite-post");
      this.favoritePostGet({ page: 1, displayedNum: 9999 });
      this.showToast({ info: "已刪除收藏分類" });
    },
    renameCollectionCategory() {
      if (this.renameBuffer.length > 20) {
        return;
      }
      if (this.renameBuffer !== "") {
        axios
          .post(
            `${serverUrl}thematic_learning/collect/${this.myCollectionCategoryId}`,
            {
              title: this.renameBuffer,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            this.showToast({ info: `分類名稱改為「${this.renameBuffer}」` });
            // console.log(res);
            for (let i = 0; i < this.myCollectionCategory.length; i++) {
              if (
                this.myCollectionCategory[i].id === this.myCollectionCategoryId
              ) {
                this.specifyCategoryPostTitle = this.renameBuffer;
                this.myCollectionCategory[i].title = this.renameBuffer;
                this.renameController = false;
              }
            }
            for (let j = 0; j < this.specifyCategoryPostList.length; j++) {
              this.specifyCategoryPostList[j].description2 = this.renameBuffer;
            }
          })
          .catch((error) => {
            console.error(error);
            this.showToast({ info: `分類名稱重複` });
          });
      } else {
        // console.log("請輸入新的收藏分類名稱");
      }
    },
    cleanRenameInput() {
      this.renameBuffer = "";
    },
    newCollection(obj: { id: number; title: string }) {
      this.myCollectionCategory.unshift({
        id: obj.id,
        title: obj.title,
        image: null,
      });
      this.showToast({ info: "成功新增收藏分類" });
    },
    newCollectionError() {
      this.showToast({ info: "收藏分類名稱已經存在" });
    },
    showAllPost() {
      this.pageChange("all-favorite-post");
      this.favoritePostGet({ page: 1, displayedNum: 9999999 });
    },
    moveToCollectDone(obj: { title: string }) {
      // 如果 this.favoritePostList 有資料，代表目前是顯示全部的收藏貼文
      if (this.favoritePostList.length > 0) {
        for (let i = 0; i < this.favoritePostList.length; i++) {
          if (this.favoritePostList[i].id === this.followPostInfo.postId) {
            this.favoritePostList[i].collect = obj.title;
            let buffer = this.favoritePostList[i];
            buffer.description2 = obj.title;
            this.favoritePostList.splice(i, 1);
            this.favoritePostList.unshift(buffer);
          }
        }
      }
      // 如果 this.specifyCategoryPostList 有資料，代表目前是顯示特定分類的收藏貼文
      else if (this.specifyCategoryPostList.length > 0) {
        for (let i = 0; i < this.specifyCategoryPostList.length; i++) {
          if (
            this.specifyCategoryPostList[i].id === this.followPostInfo.postId
          ) {
            this.specifyCategoryPostList[i].collect = obj.title;
            let buffer = this.specifyCategoryPostList[i];
            buffer.description2 = obj.title;
            this.specifyCategoryPostList.splice(i, 1);
            // console.log(this.specifyCategoryPostList);
          }
        }
      }
      this.closeAddToFavoriteClassificationModal();
      let screenWidth = window.innerWidth;
      if (screenWidth > 1024 && obj.title.length > 3) {
        let buffer = obj.title.substr(0, 3) + "...";
        this.showToast({ info: `已移至「${buffer}」收藏分類` });
      } else {
        this.showToast({ info: `已移至「${obj.title}」收藏分類` });
      }
    },
    noSelectCollectionCategoryId() {
      this.showToast({ info: `請選擇一個收藏分類` });
    },
  },
  watch: {
    renameBuffer: function () {
      if (this.renameBuffer.length > 20) {
        this.nameLimit = true;
      } else {
        this.nameLimit = false;
      }
    },
  },
  mounted() {
    this.favoritePostGet({ page: 1, displayedNum: 9999 });
    // 取得收藏分類清單
    this.collectionCategoryGet();
    // Modal 建立
    this.addToFavoriteClassification = new Modal(
      document.getElementById(`${this.modalId}`)! as HTMLElement
    );

    let addToModal = document.getElementById(`${this.modalId}`)! as HTMLElement;
    addToModal.addEventListener("show.bs.modal", function () {
      addToModalInitialization();
    });
    let addToModalInitialization = () => {
      (this.$refs.AddToFavoriteClassificationModal as any).showChange(
        "new-collection"
      );
    };

    this.newClassification = new Modal(
      document.getElementById("new-classification")! as HTMLElement
    );

    this.deleteClassification = new Modal(
      document.getElementById("delete-classification")! as HTMLElement
    );

    document.body.addEventListener("click", () => {
      this.showMoreOptions = false;
    });
  },
  beforeUnmount() {
    this.addToFavoriteClassification = null;
    this.newClassification = null;
    this.deleteClassification = null;
  },
});
